import { observer } from "mobx-react";
import React, { RefObject } from "react";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { DataTableFilterService } from "../../services/DataTableFilterService";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import { OverSlaKibanaSummaryByTujuanContainer } from "./OverSlaKibanaSummaryByTujuanContainer";
import { OverSlaKibanaSummaryByPartnerContainer } from "./OverSlaKibanaSummaryByPartnerContainer";
import { OverSlaKibanaSummaryByLastStatusContainer } from "./OverSlaKibanaSummaryByLastStatusContainer";
import { Fieldset } from "primereact/fieldset";
import { OverSlaKibanaSummaryByLastTrackingSiteProvinceContainer } from "./OverSlaKibanaSummaryByLastTrackingSiteProvinceContainer";
import { OverSlaKibanaSummaryByLastTrackingSiteCityContainer } from "./OverSlaKibanaSummaryByLastTrackingSiteCityContainer";
import { OverSlaKibanaSummaryByServiceTypeContainer } from "./OverSlaKibanaSummaryByServiceTypeContainer";
import { OverSlaKibanaSummaryByLastTrackingSiteContainer } from "./OverSlaKibananSummaryByLastTrackingSiteContainer";
import { OverSlaKibanaSummaryByStageContainer } from "./OverSlaKibanaSummaryByStageContainer";

@observer
export class OverSlaKibanaSummaryContainer extends React.Component<any, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  filterParams;
  refTujuan: RefObject<
    OverSlaKibanaSummaryByTujuanContainer
  > = React.createRef();
  refPartner: RefObject<
    OverSlaKibanaSummaryByPartnerContainer
  > = React.createRef();
  refLastTrackingSite: RefObject<
    OverSlaKibanaSummaryByLastTrackingSiteContainer
  > = React.createRef();
  refLastStatus: RefObject<
    OverSlaKibanaSummaryByLastStatusContainer
  > = React.createRef();
  refServiceType: RefObject<
    OverSlaKibanaSummaryByServiceTypeContainer
  > = React.createRef();
  refStage: RefObject<OverSlaKibanaSummaryByStageContainer> = React.createRef();

  constructor(props, context) {
    super(props, context);
    this.state = {
      tujuanCollapsed: false,
      partnerCollapsed: false,
      lastTrackingSiteCollapsed: false,
      lastStatusCollapsed: false,
      serviceTypeCollapsed: false
    };
  }

  loadData(filterParams: any) {
    this.filterParams = filterParams;
    this.refTujuan.current!.loadData(this.filterParams);
    this.refPartner.current!.loadData(this.filterParams);
    this.refLastTrackingSite.current!.loadData(this.filterParams);
    this.refLastStatus.current!.loadData(this.filterParams);
    this.refServiceType.current!.loadData(this.filterParams);
    this.refStage.current!.loadData(this.filterParams);
    console.log(JSON.stringify(filterParams), "filter params");
  }

  render() {
    return (
      <div>
        <div className="row">
          <div className="col-md-3">
            <Fieldset
              legend="Perwakilan Tujuan"
              toggleable={true}
              collapsed={this.state.tujuanCollapsed}
              onToggle={e => this.setState({ tujuanCollapsed: e.value })}
            >
              <OverSlaKibanaSummaryByTujuanContainer ref={this.refTujuan} />
            </Fieldset>
          </div>

          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <Fieldset
                  legend="Gerai Terakhir"
                  toggleable={true}
                  collapsed={this.state.lastTrackingSiteCollapsed}
                  onToggle={e =>
                    this.setState({ lastTrackingSiteCollapsed: e.value })
                  }
                >
                  <OverSlaKibanaSummaryByLastTrackingSiteContainer
                    ref={this.refLastTrackingSite}
                  />
                </Fieldset>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <div className="row">
              <div className="col-md-12">
                <Fieldset
                  legend="Layanan"
                  toggleable={true}
                  collapsed={this.state.serviceTypeCollapsed}
                  onToggle={e =>
                    this.setState({ serviceTypeCollapsed: e.value })
                  }
                >
                  <OverSlaKibanaSummaryByServiceTypeContainer
                    ref={this.refServiceType}
                  />
                </Fieldset>
              </div>
              <div className="col-md-12 mt-10">
                <Fieldset
                  legend="Stage"
                  toggleable={true}
                  collapsed={this.state.stageCollapsed}
                  onToggle={e => this.setState({ stageCollapsed: e.value })}
                >
                  <OverSlaKibanaSummaryByStageContainer ref={this.refStage} />
                </Fieldset>
              </div>
              <div className="col-md-12 mt-10">
                <Fieldset
                  legend="Status Terakhir"
                  toggleable={true}
                  collapsed={this.state.lastStatusCollapsed}
                  onToggle={e =>
                    this.setState({ lastStatusCollapsed: e.value })
                  }
                >
                  <OverSlaKibanaSummaryByLastStatusContainer
                    ref={this.refLastStatus}
                  />
                </Fieldset>
              </div>
            </div>
          </div>

          <div className="col-md-3">
            <Fieldset
              legend="Partner"
              toggleable={true}
              collapsed={this.state.partnerCollapsed}
              onToggle={e => this.setState({ partnerCollapsed: e.value })}
            >
              <OverSlaKibanaSummaryByPartnerContainer ref={this.refPartner} />
            </Fieldset>
          </div>
        </div>
      </div>
    );
  }
}

import { Formik } from 'formik';
import { Calendar } from 'primereact/calendar';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import React, { RefObject } from 'react';
import { Box, Flex } from 'reflexbox';
import { Panel } from 'primereact/panel';
import { LAYANAN_OPTIONS } from "../../constants";

export interface IReturnInProgressFilterContainerValue {
  startDate?: Date;
  endDate?: Date;
  customer?: string;
  partner?: string;
  serviceType?: any;
  isCod?: any;
  asal?: any;
  asalKota?: any;
  asalKecamatan?: any;
  tujuan?: any;
  tujuanKota?: any;
  tujuanKecamatan?: any;
  trackingSite?: any;
  city?: any;
  lastStatus?: any;
  [key: string]: any;
}

export class ReturnInProgressKibanaFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: IReturnInProgressFilterContainerValue = {
    startDate: undefined,
    endDate: undefined,
    customer: '',
    partner: '',
    serviceType: '',
    isCod: '',
    asal: '',
    asalKota: '',
    asalKecamatan: '',
    tujuan: '',
    tujuanKota: '',
    tujuanKecamatan: '',
    lastStatus: '',
    trackingSite: '',
    city: '',
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionYN = [
    {
      label: 'Semua',
      value: '',
    },
    {
      label: 'Ya',
      value: 'Y',
    },
    {
      label: 'Tidak',
      value: 'N',
    },
  ];

  constructor(props) {
    super(props);
    this.state = {
      filterCollapsed: false
    };
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    const currentFormValues = (this.refFormik.current) ? this.refFormik.current.state.values : {};
    const newFormValues = Object.assign({}, currentFormValues, values);
    this.refFormik.current!.setState(newFormValues);
    this.initialFormValues = newFormValues;
    return newFormValues;
  }

  render() {
    return (
      <Panel header="Filter" toggleable={true} collapsed={this.state.filterCollapsed} onToggle={(e) => this.setState({ filterCollapsed: e.value })}>
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}>
          {formikProps => (
            <Flex wrap w={1}>
              <Box w={350}>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Periode</label>
                    <div className="col-md-7">
                      <Flex column>
                        <Calendar
                          name="startDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          maxDate={formikProps.values.endDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange('startDate')}
                          value={formikProps.values.startDate}
                          showButtonBar={true}
                        />
                        <div className="mt-2 mb-2 text-center" style={{ width: '172px' }}>s/d</div>
                        <Calendar
                          name="endDate"
                          readOnlyInput={true}
                          dateFormat="dd-mm-yy"
                          showWeek={true}
                          minDate={formikProps.values.startDate}
                          onBlur={formikProps.handleBlur}
                          onChange={formikProps.handleChange('endDate')}
                          value={formikProps.values.endDate}
                          showButtonBar={true}
                        />
                      </Flex>
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Customer</label>
                    <div className="col-md-7">
                      <InputText
                        name="customer"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.customer}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Partner</label>
                    <div className="col-md-7">
                      <InputText
                        name="partner"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.partner}
                      />
                    </div>
                  </div>
                </Flex>
              </Box>
              <Box>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Layanan</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={LAYANAN_OPTIONS}
                        onChange={formikProps.handleChange('serviceType')}
                        value={formikProps.values.serviceType}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">COD</label>
                    <div className="col-md-7">
                      <Dropdown
                        options={this.optionYN}
                        onChange={formikProps.handleChange('isCod')}
                        value={formikProps.values.isCod}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Gerai Terakhir</label>
                    <div className="col-md-7">
                      <InputText
                        name="trackingSite"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.trackingSite}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Kota Terakhir</label>
                    <div className="col-md-7">
                      <InputText
                        name="city"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.city}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Status Terakhir</label>
                    <div className="col-md-7">
                      <InputText
                        name="lastStatus"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.lastStatus}
                      />
                    </div>
                  </div>
                </Flex>
              </Box>
              <Box>
                <Flex column>
                  <div className="form-group">
                    <label className="col-md-5">Asal</label>
                    <div className="col-md-7">
                      <InputText
                        name="asal"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.asal}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Asal (Kota)</label>
                    <div className="col-md-7">
                      <InputText
                        name="asalKota"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.asalKota}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Asal (Kecamatan)</label>
                    <div className="col-md-7">
                      <InputText
                        name="asalKecamatan"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.asalKecamatan}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Tujuan</label>
                    <div className="col-md-7">
                      <InputText
                        name="tujuan"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.tujuan}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Tujuan (Kota)</label>
                    <div className="col-md-7">
                      <InputText
                        name="tujuanKota"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.tujuanKota}
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label className="col-md-5">Tujuan (Kecamatan)</label>
                    <div className="col-md-7">
                      <InputText
                        name="tujuanKecamatan"
                        type="text"
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange}
                        value={formikProps.values.tujuanKecamatan}
                      />
                    </div>
                  </div>
                </Flex>
              </Box>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
import { observer } from 'mobx-react';
import moment from 'moment';
import { Button } from 'primereact/button';
import { Column, ColumnProps } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { Paginator } from 'primereact/paginator';
import React, { RefObject } from 'react';
import { RouterProps } from 'react-router';
import { Flex } from 'reflexbox';

import qs from 'qs';
import { DataSource } from '../../models/DataSource';
import { DataTableFilterService } from '../../services/DataTableFilterService';
import { MonitoringRestService } from '../../services/MonitoringRestService';
import { DependencyContainer } from '../../utils/DependencyInjection';
import { IReturnInProgressFilterContainerValue, ReturnInProgressKibanaFilterContainer } from './ReturnInProgressKibanaFilterContainer';
import { AwbDetailModalComponent } from '../awb/AwbDetailModalComponent';
import { Dialog } from 'primereact/dialog';
import { Panel } from 'primereact/panel';
import { DateHelper } from '../../utils/DateHelper';
import { Growl } from 'primereact/growl';

export interface IReturnInProgressContainerQuery {
  filters: IReturnInProgressFilterContainerValue;
}

@observer
export class ReturnInProgressKibanaContainer extends React.Component<RouterProps, any> {
  dataTableFilterService = DependencyContainer.get(DataTableFilterService);
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  data;
  dt;
  growl;
  dataSource = new DataSource<any>();
  refFilter: RefObject<ReturnInProgressKibanaFilterContainer> = React.createRef();
  refAwbDetailModal: RefObject<AwbDetailModalComponent> = React.createRef();

  constructor(props) {
    super(props);
    this.state = {
      exportExcelLoading: false,
      showExportDialog: false,
      filterCollapsed: false,
      sortField: null,
      sortOrder: null,
    }
  }

  componentDidMount() {
    const queryFilter = (this.props.history.location.search)
      ? qs.parse(
        this.props.history.location.search.substr(1),
      ) as any
      : {} as any;

    if (queryFilter && queryFilter.filters) {
      if (this.refFilter.current) {
        const newFilters = this.refFilter.current.setFormValues(queryFilter.filters);
        this.loadData(newFilters);
      }
    }

    this.dataSource.onPageChange.subscribe(() => {
      this.loadData();
    });
  }

  loadData(filterParams: any = null) {
    const pagingParams = { limit: this.dataSource.rows, page: this.dataSource.page };
    const sortingParams = { sortField: this.state.sortField, sortOrder: (this.state.sortOrder === -1) ? 'desc' : 'asc' }

    const fParams = (filterParams)
      ? Object.assign({}, filterParams, pagingParams, sortingParams)
      : Object.assign({}, this.refFilter.current!.formValues, pagingParams, sortingParams);

    if (fParams.startDate) fParams.startDate = DateHelper.formatLocalDateForQueryParam(fParams.startDate);
    if (fParams.endDate) fParams.endDate = DateHelper.formatLocalDateForQueryParam(fParams.endDate);

    this.dataSource.loading = true;
    this.monitoringRestService
      .post('return-in-progress/kibana', fParams)
      .subscribe(
        response => {
          this.data = response;
          this.populateDataSource();
        },
        (err) => {
          this.dataSource.loading = false;
        },
        () => {
          this.dataSource.loading = false;
        }
      );
  }


  downloadCsv(filterParams: any = null) {
    this.setState({ exportExcelLoading: true, showExportDialog: true });

    const fParams = (filterParams)
      ? Object.assign({}, filterParams)
      : Object.assign({}, this.refFilter.current!.formValues);

    if (fParams.startDate) fParams.startDate = DateHelper.formatLocalDateForQueryParam(fParams.startDate);
    if (fParams.endDate) fParams.endDate = DateHelper.formatLocalDateForQueryParam(fParams.endDate);

    fParams.format = "xls";

    this.monitoringRestService
      .post('return-in-progress/kibana', fParams)
      .subscribe(
        response => {
          if (response) {
            this.openCsv(response);
          }
        },
        () => {
          this.setState({ exportExcelLoading: false, showExportDialog: false });
          let msg = { severity: 'error', summary: 'Error Message', detail: 'Export failed' };
          this.growl.show(msg);
        },
        () => {
          this.setState({ exportExcelLoading: false, showExportDialog: false });
          let msg = { severity: 'success', summary: 'Success Message', detail: 'Export Success' };
          this.growl.show(msg);
        },
      );
  }

  openCsv(url) {
    const win = window.open(url, '_blank');
    if (win) win.focus();
  }

  populateDataSource() {
    if (this.data.data && this.data.data.length) {
      this.dataSource.setData(this.data.data);
      this.dataSource.setTotal(this.data.count);
    } else {
      this.dataSource.setData([]);
      this.dataSource.setTotal(0);
    }
  }

  onClickAwbNumber = (awbNumber) => {
    this.refAwbDetailModal.current!.handleShow(awbNumber);
  }

  columnAwbNumber = (rowData, column: ColumnProps) => {
    return (
      <a onClick={() => this.onClickAwbNumber(rowData[column.field!])}>{rowData[column.field!]}</a>
    );
  }

  columnDateFormat = (rowData, column: ColumnProps) => {
    return (
      (rowData[column.field!] != null) ? moment(new Date(rowData[column.field!])).utc().format('DD-MM-YYYY HH:mm') : ''
    );
  }

  async onSorting(e) {
    if (e && e.sortField) {
      const sortOrder = (this.state.sortField === e.sortField)
        ? (this.state.sortOrder === 1) ? -1 : 1
        : 1;
      await this.setState({ sortField: e.sortField, sortOrder: sortOrder })
    } else {
      await this.setState({ sortField: null, sortOrder: null })
    }

    console.log(e);
    console.log(this.state);
    await this.loadData();
  }

  render() {
    const footer = 'Total data: ' + this.dataSource.total;

    return (
      <div>
        <AwbDetailModalComponent ref={this.refAwbDetailModal} />

        <Dialog header="Informasi" visible={this.state.showExportDialog} style={{ width: '40vw' }} modal={true} onHide={() => this.setState({ showExportDialog: false })}>
          Export excel sedang berjalan, mohon jangan tinggalkan halaman ini
        </Dialog>

        <Growl ref={el => this.growl = el} style={{ marginTop: '75px' }} />

        <Flex className="card w-full">
          <Flex className="w-full" column>
            <ReturnInProgressKibanaFilterContainer ref={this.refFilter} />
            <br />
            <Flex>
              <Button
                className="p-button-success mr-2"
                label="Tampilkan"
                onClick={() => {
                  this.dataSource.setPage(1);
                }}
              />
              <Button
                className="p-button-primary"
                label="Export Excel"
                icon={(this.state.exportExcelLoading) ? "pi pi-spin pi-spinner" : ""}
                disabled={(this.state.exportExcelLoading)}
                onClick={() => this.downloadCsv()}
              />
            </Flex>
          </Flex>
        </Flex>

        <div className="card">
          <DataTable
            ref={(el) => this.dt = el}
            emptyMessage={'Tidak ada data untuk ditampilkan.'}
            scrollable={true}
            scrollHeight="550px"
            style={{ width: '100%' }}
            className="mt-4"
            value={this.dataSource.data}
            loading={this.dataSource.loading}
            sortField={this.state.sortField}
            sortOrder={this.state.sortOrder}
            onSort={
              (e) => {
                this.onSorting(e);
              }
            }
            // onValueChange={() => this.loadData()}
            footer={footer}>
            <Column
              field="awbNumber"
              header="No Resi"
              sortable={true}
              style={{ width: '130px', "vertical-align": 'text-top' }}
              body={this.columnAwbNumber}
            />
            <Column
              field="awbDate"
              header="Tgl. Transaksi"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: '145px', "vertical-align": 'text-top' }}
            />
            <Column
              field="pengirim"
              header="Pengirim"
              sortable={false}
              style={{ width: '200px', "vertical-align": 'text-top' }}
            />
            <Column
              field="asal"
              header="Asal"
              sortable={true}
              style={{ width: '120px', "vertical-align": 'text-top' }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div><div><b>{rowData[column.field!]}</b></div><div><small><span>{rowData['asalKota']} ({rowData['asalKecamatan']})</span></small></div></div>
                )
              }}
            />
            <Column
              field="tujuan"
              header="Tujuan"
              sortable={true}
              style={{ width: '120px', "vertical-align": 'text-top' }}
              body={(rowData, column: ColumnProps) => {
                return (
                  <div><div><b>{rowData[column.field!]}</b></div><div><small><span>{rowData['tujuanKota']} ({rowData['tujuanKecamatan']})</span></small></div></div>
                )
              }}
            />
            <Column
              field="layanan"
              header="Layanan"
              sortable={true}
              style={{ width: '90px', "vertical-align": 'text-top' }}
            />
            <Column
              field="perwakilan"
              header="Perwakilan"
              sortable={true}
              style={{ width: '100px', "vertical-align": 'text-top' }}
            />
            <Column
              field="codNilai"
              header="Nilai COD"
              sortable={true}
              style={{ width: '100px', "vertical-align": 'text-top' }}
              body={(rowData, column: ColumnProps) => {
                const colValue = rowData[column.field!];
                const fmtValue = colValue
                  ? rowData[column.field!].toLocaleString()
                  : "-";
                return <span>{fmtValue}</span>;
              }}
            />
            <Column
              field="usiaPaket"
              header="Usia Paket"
              sortable={true}
              style={{ width: '80px', "vertical-align": 'text-top' }}
            />
            <Column
              field="overslaDaysInternal"
              header="Over SLA"
              sortable={true}
              filter={false}
              style={{ width: '120px', "vertical-align": 'text-top' }}
              body={(rowData, column: ColumnProps) => {
                const tglOverSla = moment(rowData["SlaMaxDateTimeInternal"]);
                let sTglOverSla: string = "-";
                if (tglOverSla.isValid()) {
                  sTglOverSla = tglOverSla.utc().format("DD-MM-YYYY");
                }

                const overSlaDays =
                  rowData[column.field!] && rowData[column.field!] > 0
                    ? rowData[column.field!]
                    : "-";

                return (
                  <div>
                    <div>
                      <b>{overSlaDays} hari</b>
                    </div>
                    <div>
                      <small>{sTglOverSla}</small>
                    </div>
                  </div>
                );
              }}
            />
            <Column
              field="manifestTrackingSiteName"
              header="Gerai Asal"
              sortable={true}
              style={{ width: '120px', "vertical-align": 'text-top' }}
            />
            <Column
              field="lastValidTrackingSiteCode"
              header="Gerai Terakhir"
              sortable={true}
              style={{ width: '120px', "vertical-align": 'text-top' }}
            />
            <Column
              field="lastValidTrackingSiteName"
              header="Gerai Terakhir"
              sortable={true}
              style={{ width: '120px', "vertical-align": 'text-top' }}
            />
            <Column
              field="lastValidTrackingSiteCity"
              header="Kota Terakhir"
              sortable={true}
              style={{ width: '120px', "vertical-align": 'text-top' }}
            />
            <Column
              field="lastValidTrackingType"
              header="Last Status"
              sortable={true}
              style={{ width: '90px', "vertical-align": 'text-top' }}
            />
            <Column
              field="lastValidTrackingDateTime"
              header="Tgl. Last Status"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: '145px', "vertical-align": 'text-top' }}
            />
            <Column
              field="lastProblemTrackingType"
              header="Last Problem"
              sortable={true}
              style={{ width: '120px', "vertical-align": 'text-top' }}
            />
            <Column
              field="lastProblemDateTime"
              header="Tgl. Last Problem"
              body={this.columnDateFormat}
              sortable={true}
              style={{ width: '145px', "vertical-align": 'text-top' }}
            />
          </DataTable>
          <Paginator
            totalRecords={this.dataSource.total}
            first={this.dataSource.offset}
            rows={this.dataSource.rows}
            rowsPerPageOptions={this.dataSource.rowsOptions}
            onPageChange={e => this.dataSource.fromPageState(e)}
          />
        </div>
      </div>
    );
  }
}

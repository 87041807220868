import React from "react";
import {Button, Card, CardContent, Grid} from "@material-ui/core";
import { MonitoringRestService } from '../../services/MonitoringRestService';
import {DependencyContainer} from "../../utils/DependencyInjection";
import {Redirect} from "react-router-dom";

class RoleAccess extends React.Component<any, any> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      redirectDashboard: false,
      roles: [],
      loading: false,
    };
  }

  handleOnclick = (data) => {
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;

    this.setState({loading: true});
    this.monitoringRestService.post('/role-permission/list', {
      role_id: String(data.roleId),
    })
    .subscribe(
      response => {
        console.log(response)
        const accessMenu = response.menu.filter((menu: { is_access: boolean, menu: string }) => menu.is_access)
        const permissions = accessMenu.map((menu: { is_access: boolean, menu: string }) => menu.menu)

        userData.role_permissions = permissions;
        userData.role_id = data.roleId;
        userData.role_name = data.roleName;

        localStorage.setItem("userData", JSON.stringify(userData));
        localStorage.setItem("branchCode", data.branchCode);
        this.setState({redirectDashboard: true});
      },
      err => {
        console.log(err)
        this.setState({ loading: false })
      },
      () => {
        this.setState({ loading: false })
      },
    );
  };
  logout() {
    localStorage.setItem("userData", "");
    localStorage.setItem("branchCode", "");

    localStorage.clear();
    this.setState({redirect: true});
  }

  getRoles() {
    const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.userData)
    : null;

    this.setState({loading: true});
    this.monitoringRestService.post('/auth/list-role-access', {})
    .subscribe(
      response => {
        userData.role_name = response;
        localStorage.setItem("userData", JSON.stringify(userData));
        this.setState({roles: response});
      },
      err => {
        console.log(err)
        this.setState({loading: false});
      },
      () => {
        this.setState({loading: false});
      },
    );
  }

  componentDidMount() {
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    if (userData !== null && typeof userData.role_name === "string") {
      this.getRoles()
    } else {
      this.setState({roles: userData.role_name});
    }
  }
  
  render() {
    const userData = localStorage.getItem("userData")
      ? JSON.parse(localStorage.userData)
      : null;
    if (userData === null) {
      return <Redirect to={"/"} />;
    }
    if (this.state.redirect) {
      return <Redirect to={"/"} />;
    }
    if (this.state.redirectDashboard) {
      return <Redirect to={"/admin/dashboard"} />;
    }
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          minHeight: "100vh",
          alignItems: "center",
          justifyContent: "flex-start",
        }}
      >
        <Card
          style={{
            minWidth: 600,
            margin: "10em",
            padding: "50px 25px",
            borderRadius: "13px",
            boxShadow: "0px 0px 30px 0px #565656",
          }}
        >
          <Button
            variant='contained'
            color='primary'
            style={{
              float: "right",
              width: "20%",
              height: "35px",
              fontSize: "13px",
            }}
            onClick={(e) => {
              this.logout();
            }}
          >
            Keluar
          </Button>
          <CardContent>
            <Grid
              container
              direction='row'
              justify='center'
              alignItems='center'
            >
              <div
                style={{
                  fontSize: "23px",
                  textAlign: "center",
                  paddingBottom: "16px",
                  paddingTop: "16px",
                }}
              >
                Pilih Akses Anda
              </div>
            </Grid>
            <Grid>
              <div
                style={{
                  maxHeight: "500px",
                  overflowY: "auto",
                  paddingRight: "15px",
                  paddingLeft: "15px",
                  overflowX: "hidden",
                }}
              >
                {this.state.roles.map((data, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        borderBottom: "1px solid #cccccc",
                        padding: "10px 0px 10px 0px",
                      }}
                    >
                      <Grid container spacing={8}>
                        <Grid item xs={9}>
                          {data.value}
                        </Grid>
                        <Grid item xs={3}>
                          <Button
                            variant='contained'
                            color='secondary'
                            style={{
                              float: "right",
                              width: "20%",
                              height: "35px",
                              fontSize: "13px",
                            }}
                            onClick={(e) => {
                              this.handleOnclick(data);
                            }}
                          >
                            Masuk
                          </Button>
                        </Grid>
                      </Grid>
                    </div>
                  );
                })}
              </div>
            </Grid>
          </CardContent>
        </Card>
      </div>
    );
  }
}

export default RoleAccess;

import { Formik } from "formik";
import { observable } from "mobx";
import { observer } from "mobx-react";
import qs from "qs";
import React, { RefObject } from "react";
import { Link } from "react-router-dom";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import _ from "lodash";
import { DateHelper } from "../../utils/DateHelper";
import Loader from "react-loader-spinner";

@observer
export class OverSlaKibanaSummaryByLastStatusContainer extends React.Component<
any,
any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  refFormik: RefObject<Formik> = React.createRef();

  @observable data;
  filterParams;

  constructor(props) {
    super(props);

    this.state = {
      loadOverSlaData: false
    };
  }

  public async loadData(
    filterParams,
  ) {
    this.data = [];
    this.filterParams = filterParams;
    const fParams = Object.assign({}, filterParams);
    this.setState({ loadOverSlaData: "load" });

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    this.monitoringRestService.post("over-sla/sum/status", fParams).subscribe(
      response => {
        this.data = response;
      },
      () => {
        this.setState({ loadOverSlaData: false });
      },
      () => {
        this.setState({ loadOverSlaData: true });
      }
    );
  }

  render() {
    return (
      <div>
        {this.data && this.data.length > 0 ? (
          <div className="mt-5">
            <p
              className="font-bold"
              style={{ fontSize: "18px", margin: "10px 0" }}
            >
              Total Resi: {(this.data ? _.sumBy(this.data, "doc_count") : 0).toLocaleString()}
            </p>
            <hr />
          </div>
        ) : (
            ""
          )}
        <div>
          {this.data && this.data.length > 0 ? (
            this.data.map((d, index) => (
              <div key={index}>
                <span className="font-bold">{d.key}: </span>
                <Link
                  to={{
                    pathname: "/admin/monitoring/oversla-kibana",
                    search: qs.stringify({
                      filters: Object.assign({}, this.filterParams, {
                        lastStatus: d.key
                      }),
                      activeTabIndex: 1
                    })
                  }}
                  target="_blank"
                >
                  {d.doc_count.toLocaleString()}
                </Link>
              </div>
            ))
          ) : (
              <div>
                {this.state.loadOverSlaData == "load" ? (
                  <Loader
                    type="ThreeDots"
                    color="#c8c8c8"
                    height="50"
                    width="50"
                  />
                ) : (
                    <p>Tidak ada data</p>
                  )}
              </div>
            )}
        </div>
      </div>
    );
  }
}

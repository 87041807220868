import { Formik } from "formik";
import { observable } from "mobx";
import { observer } from "mobx-react";
import qs from "qs";
import React, { RefObject } from "react";
import { Link } from "react-router-dom";
import { DependencyContainer } from "../../utils/DependencyInjection";
import { MonitoringRestService } from "../../services/MonitoringRestService";
import _ from "lodash";
import { DateHelper } from "../../utils/DateHelper";
import Loader from "react-loader-spinner";
import { TabView, TabPanel } from "primereact/tabview";
import { merge, forkJoin } from "rxjs";

@observer
export class OverSlaKibanaSummaryByLastTrackingSiteContainer extends React.Component<
  any,
  any
> {
  monitoringRestService = DependencyContainer.get(MonitoringRestService);

  refFormik: RefObject<Formik> = React.createRef();

  @observable trackingSiteData;
  @observable trackingSiteCityData;
  @observable trackingSiteProvinceData;
  filterParams;

  constructor(props) {
    super(props);

    this.state = {
      loadingData: false,
    };
  }

  public async loadData(filterParams) {
    this.trackingSiteData = [];
    this.filterParams = filterParams;
    const fParams = Object.assign({}, filterParams);

    if (fParams.startDate)
      fParams.startDate = DateHelper.formatLocalDateForQueryParam(
        fParams.startDate
      );
    if (fParams.endDate)
      fParams.endDate = DateHelper.formatLocalDateForQueryParam(
        fParams.endDate
      );
    const prom1 = this.monitoringRestService.post(
      "over-sla/sum/province",
      fParams
    );

    const prom2 = this.monitoringRestService.post("over-sla/sum/city", fParams);

    const prom3 = this.monitoringRestService.post("over-sla/sum/site", fParams);

    this.setState({ loadingData: true });
    forkJoin([prom1, prom2, prom3]).subscribe(
      (responses: any) => {
        if (responses) {
          this.trackingSiteProvinceData = responses[0];
          this.trackingSiteCityData = responses[1];
          this.trackingSiteData = responses[2];
        } else {
          this.trackingSiteProvinceData = [];
          this.trackingSiteCityData = [];
          this.trackingSiteData = [];
        }

        this.setState({ loadingData: false });
      },
      () => {
        this.setState({ loadingData: false });
      },
      () => {
        this.setState({ loadingData: false });
      }
    );

    // this.monitoringRestService
    //   .post('oversla-summary-tracking-site', fParams)
    //   .subscribe(
    //     response => {
    //       this.trackingSiteData = response;
    //     },
    //     () => {
    //       this.setState({ loadingData: false });
    //     },
    //     () => {
    //       this.setState({ loadingData: true });
    //     },
    //   );
  }

  render() {
    return (
      <div>
        <div>
          {this.state.loadingData ? (
            <Loader type="ThreeDots" color="#c8c8c8" height="50" width="50" />
          ) : (
            <div>
              <div>
                {this.trackingSiteData && this.trackingSiteData.length > 0 ? (
                  <div className="mt-5">
                    <p
                      className="font-bold"
                      style={{ fontSize: "18px", margin: "10px 0" }}
                    >
                      Total Resi:{" "}
                      {(this.trackingSiteData
                        ? _.sumBy(this.trackingSiteData, "doc_count")
                        : 0
                      ).toLocaleString()}
                    </p>
                    <hr />
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div>
                <TabView>
                  <TabPanel header="Province">
                    <div>
                      {this.trackingSiteProvinceData &&
                      this.trackingSiteProvinceData.length > 0 ? (
                        this.trackingSiteProvinceData.map((d, index) => (
                          <div key={index}>
                            <span className="font-bold">{d.key}: </span>
                            <Link
                              to={{
                                pathname: "/admin/monitoring/oversla-kibana",
                                search: qs.stringify({
                                  filters: Object.assign(
                                    {},
                                    this.filterParams,
                                    {
                                      lastTrackingSiteProvince: d.key,
                                    }
                                  ),
                                  activeTabIndex: 1,
                                }),
                              }}
                              target="_blank"
                            >
                              {d.doc_count.toLocaleString()}
                            </Link>
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>Tidak ada data</p>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel header="Kota">
                    <div>
                      {this.trackingSiteCityData &&
                      this.trackingSiteCityData.length > 0 ? (
                        this.trackingSiteCityData.map((d, index) => (
                          <div key={index}>
                            <span className="font-bold">{d.key}: </span>
                            <Link
                              to={{
                                pathname: "/admin/monitoring/oversla-kibana",
                                search: qs.stringify({
                                  filters: Object.assign(
                                    {},
                                    this.filterParams,
                                    {
                                      lastTrackingSiteCity: d.key,
                                    }
                                  ),
                                  activeTabIndex: 1,
                                }),
                              }}
                              target="_blank"
                            >
                              {d.doc_count.toLocaleString()}
                            </Link>
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>Tidak ada data</p>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                  <TabPanel header="Gerai">
                    <div>
                      {this.trackingSiteData &&
                      this.trackingSiteData.length > 0 ? (
                        this.trackingSiteData.map((d, index) => (
                          <div key={index}>
                            <span className="font-bold">{d.key}: </span>
                            <Link
                              to={{
                                pathname: "/admin/monitoring/oversla-kibana",
                                search: qs.stringify({
                                  filters: Object.assign(
                                    {},
                                    this.filterParams,
                                    { lastTrackingSite: d.key }
                                  ),
                                  activeTabIndex: 1,
                                }),
                              }}
                              target="_blank"
                            >
                              {d.doc_count.toLocaleString()}
                            </Link>
                          </div>
                        ))
                      ) : (
                        <div>
                          <p>Tidak ada data</p>
                        </div>
                      )}
                    </div>
                  </TabPanel>
                </TabView>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

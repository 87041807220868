import { Formik } from "formik";
import { Calendar } from "primereact/calendar";
import { Dropdown } from "primereact/dropdown";
import { InputText } from "primereact/inputtext";
import React, { RefObject } from "react";
import { Flex } from "reflexbox";
import { SelectButton } from "primereact/selectbutton";
import { observer } from "mobx-react";
import { Panel } from "primereact/panel";
import { LAYANAN_OPTIONS } from "../../constants";

export interface IOverSlaFilterContainerValue {
  startDate?: Date;
  endDate?: Date;
  customer?: string;
  partner?: string;
  serviceType?: any;
  asal?: any;
  asalKota?: any;
  asalKecamatan?: any;
  perwakilan?: any;
  tujuan?: any;
  tujuanKota?: any;
  tujuanKecamatan?: any;
  isUsingPartner3Pl?: any;
  isCod?: any;
  delivered?: string;
  lastTrackingSiteProvince?: any;
  lastTrackingSiteCity?: any;
  lastTrackingSite?: any;
  lastStatus?: any;
  stage?: any;
  slaType?: any;
  [key: string]: any;
}

@observer
export class OverSlaKibanaFilterContainer extends React.Component<any, any> {
  dateRange: any = null;
  initialFormValues: IOverSlaFilterContainerValue = {
    startDate: new Date(),
    endDate: new Date(),
    customer: "",
    partner: "",
    serviceType: "",
    asal: "",
    asalKota: "",
    asalKecamatan: "",
    perwakilan: "",
    tujuan: "",
    tujuanKota: "",
    tujuanKecamatan: "",
    isUsingPartner3Pl: "false",
    isCod: "false",
    delivered: "false",
    lastTrackingSiteProvince: "",
    lastTrackingSiteCity: "",
    lastTrackingSite: "",
    lastStatus: "",
    stage: "",
    processStage: "",
    slaType: "internal",
    manifest: "true",
  };

  refFormik: RefObject<Formik> = React.createRef();

  optionsStage = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "FIRST MILE",
      value: "0",
    },
    {
      label: "LAST MILE",
      value: "1",
    },
    {
      label: "LINE HAUL",
      value: "2",
    },
  ];

  optionYN = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Ya",
      value: "true",
    },
    {
      label: "Tidak",
      value: "false",
    },
  ];

  optionDeliverYN = [
    {
      label: "Semua",
      value: "",
    },
    {
      label: "Ya",
      value: "true",
    },
    {
      label: "Tidak",
      value: "false",
    },
  ];

  optionSlaTypes = [
    {
      label: "Internal",
      value: "internal",
    },
    {
      label: "External",
      value: "external",
    },
  ];

  constructor(props, context) {
    super(props, context);
    this.state = {
      filterCollapsed: false,
    };

    this.onSlaTypeChange = this.onSlaTypeChange.bind(this);
  }

  get formValues() {
    if (this.refFormik.current) {
      return this.refFormik.current.state.values;
    }

    return {};
  }

  setFormValues(values) {
    if (this.refFormik.current) {
      const newFormValues = Object.assign(
        {},
        this.refFormik.current.state.values,
        values
      );
      this.refFormik.current!.setValues(newFormValues);
      this.initialFormValues = newFormValues;
      return newFormValues;
    }

    return null;
  }

  onSlaTypeChange(event) {
    if (this.refFormik.current) {
      this.refFormik.current!.setFieldValue("slaType", event.value);
      this.initialFormValues!.slaType = event.value;
    }
  }

  render() {
    return (
      <Panel
        header="Filter"
        toggleable={true}
        collapsed={this.state.filterCollapsed}
        onToggle={(e) => this.setState({ filterCollapsed: e.value })}
      >
        <Formik
          ref={this.refFormik}
          enableReinitialize
          initialValues={this.initialFormValues}
          onSubmit={() => { }}
        >
          {(formikProps) => (
            <Flex wrap w={1}>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-4">Periode</label>
                  <div className="col-md-8">
                    <Flex column>
                      <Calendar
                        name="startDate"
                        readOnlyInput={true}
                        dateFormat="dd-mm-yy"
                        showWeek={true}
                        maxDate={formikProps.values.endDate}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange("startDate")}
                        value={formikProps.values.startDate}
                        showButtonBar={true}
                      />
                      <div className="mt-2 mb-2 text-center">s/d</div>
                      <Calendar
                        name="endDate"
                        readOnlyInput={true}
                        dateFormat="dd-mm-yy"
                        showWeek={true}
                        minDate={formikProps.values.startDate}
                        onBlur={formikProps.handleBlur}
                        onChange={formikProps.handleChange("endDate")}
                        value={formikProps.values.endDate}
                        showButtonBar={true}
                      />
                    </Flex>
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Customer</label>
                  <div className="col-md-8">
                    <InputText
                      name="customer"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.customer}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Partner</label>
                  <div className="col-md-8">
                    <InputText
                      name="partner"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.partner}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Terkirim</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionDeliverYN}
                      onChange={formikProps.handleChange("delivered")}
                      value={formikProps.values.delivered}
                    />
                  </div>
                </div>
              </Flex>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-4">Layanan</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={LAYANAN_OPTIONS}
                      onChange={formikProps.handleChange("serviceType")}
                      value={formikProps.values.serviceType}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">COD</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionYN}
                      onChange={formikProps.handleChange("isCod")}
                      value={formikProps.values.isCod}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">3Pl</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionYN}
                      onChange={formikProps.handleChange("isUsingPartner3Pl")}
                      value={formikProps.values.isUsingPartner3Pl}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Province Terakhir</label>
                  <div className="col-md-8">
                    <InputText
                      name="lastTrackingSiteProvince"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.lastTrackingSiteProvince}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Kota Terakhir</label>
                  <div className="col-md-8">
                    <InputText
                      name="lastTrackingSiteCity"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.lastTrackingSiteCity}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Gerai Terakhir</label>
                  <div className="col-md-8">
                    <InputText
                      name="lastTrackingSite"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.lastTrackingSite}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Stage</label>
                  <div className="col-md-8">
                    <Dropdown
                      options={this.optionsStage}
                      onChange={formikProps.handleChange("processStage")}
                      value={formikProps.values.processStage}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-4">Status Terakhir</label>
                  <div className="col-md-8">
                    <InputText
                      name="lastStatus"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.lastStatus}
                    />
                  </div>
                </div>
              </Flex>
              <Flex column>
                <div className="form-group">
                  <label className="col-md-5">Asal</label>
                  <div className="col-md-7">
                    <InputText
                      name="asal"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.asal}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Asal (Kota)</label>
                  <div className="col-md-7">
                    <InputText
                      name="asalKota"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.asalKota}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Asal (Kecamatan)</label>
                  <div className="col-md-7">
                    <InputText
                      name="asalKecamatan"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.asalKecamatan}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Perwakilan</label>
                  <div className="col-md-7">
                    <InputText
                      name="perwakilan"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.perwakilan}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Tujuan</label>
                  <div className="col-md-7">
                    <InputText
                      name="tujuan"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.tujuan}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Tujuan (Kota)</label>
                  <div className="col-md-7">
                    <InputText
                      name="tujuanKota"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.tujuanKota}
                    />
                  </div>
                </div>
                <div className="form-group">
                  <label className="col-md-5">Tujuan (Kecamatan)</label>
                  <div className="col-md-7">
                    <InputText
                      name="tujuanKecamatan"
                      type="text"
                      onBlur={formikProps.handleBlur}
                      onChange={formikProps.handleChange}
                      value={formikProps.values.tujuanKecamatan}
                    />
                  </div>
                </div>

                {/* <div className="form-group">
                  <label className="col-md-5">Manifest</label>
                  <div className="col-md-7">
                    <Dropdown
                      options={this.optionDeliverYN}
                      onChange={formikProps.handleChange("manifest")}
                      value={formikProps.values.manifest}
                    />
                  </div>
                </div> */}
              </Flex>
              <Flex column>
                <div className="form-group">
                  <SelectButton
                    id="slaType"
                    value={formikProps.values.slaType}
                    options={this.optionSlaTypes}
                    onChange={(e) => this.onSlaTypeChange(e)}
                  ></SelectButton>
                </div>
              </Flex>
            </Flex>
          )}
        </Formik>
      </Panel>
    );
  }
}
